import React, {useEffect, useRef} from 'react';
import words from './his-voice/words.mp3'
import hisGaze from './his-image/his-loving-gaze.png';
import './App.css';

export function App() {

    const myRef = useRef();

    useEffect(() => {
        if (!!myRef.current) myRef.current.play();
    }, [myRef.current]);

    return <div
        className="App"
        style={{
            backgroundImage: `url('${hisGaze}')`,
        }}
    >
        <audio src={words} autoPlay={true} loop={true} />
    </div>
}

export default App;
